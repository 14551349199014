// extracted by mini-css-extract-plugin
export var alertRed = "planAnchor-module--alert-red--b6f6a";
export var bgBarGrey = "planAnchor-module--bg-bar-grey--68d9f";
export var bgBeige = "planAnchor-module--bg-beige--7bd04";
export var bgBeige2 = "planAnchor-module--bg-beige2--14cc0";
export var bgCopper = "planAnchor-module--bg-copper--f99db";
export var bgDaffodil = "planAnchor-module--bg-daffodil--00797";
export var bgGreyText = "planAnchor-module--bg-grey-text--f3db3";
export var bgMing = "planAnchor-module--bg-ming--e8288";
export var bgMint = "planAnchor-module--bg-mint--14a3a";
export var bgRed = "planAnchor-module--bg-red--0ccb6";
export var bgRedTrans = "planAnchor-module--bg-red-trans--e0b6f";
export var bgSand = "planAnchor-module--bg-sand--f2307";
export var bgSoftSun = "planAnchor-module--bg-softSun--76c8f";
export var bgTeal = "planAnchor-module--bg-teal--1f79f";
export var bgWhite = "planAnchor-module--bg-white--6b5e3";
export var bgWomenSharp = "planAnchor-module--bg-women-sharp--84246";
export var blackText = "planAnchor-module--black-text--a961a";
export var borderMing = "planAnchor-module--border-ming--16f66";
export var copper = "planAnchor-module--copper--890ce";
export var corners = "planAnchor-module--corners--8e076";
export var cursorNormal = "planAnchor-module--cursor-normal--22eb1";
export var cursorPointer = "planAnchor-module--cursor-pointer--0aa65";
export var customMarkdown = "planAnchor-module--customMarkdown--2dbe4";
export var dUnset = "planAnchor-module--d-unset--be23b";
export var darkGrey = "planAnchor-module--dark-grey--b0d6f";
export var dash = "planAnchor-module--dash--189a7";
export var dashBig = "planAnchor-module--dashBig--67b15";
export var deadSalmon = "planAnchor-module--dead-salmon--20abd";
export var flex = "planAnchor-module--flex--ffaf6";
export var flex1 = "planAnchor-module--flex-1--24d59";
export var fontSize12 = "planAnchor-module--font-size-12--085bf";
export var fontSize20 = "planAnchor-module--font-size-20--df426";
export var greyPlaceholder = "planAnchor-module--grey-placeholder--dc577";
export var greyText = "planAnchor-module--grey-text--8ae93";
export var h1sizing = "planAnchor-module--h1sizing--df5e7";
export var h2sizing = "planAnchor-module--h2sizing--c8f28";
export var hidden = "planAnchor-module--hidden--d549c";
export var imageAni = "planAnchor-module--imageAni--e19e0";
export var imageZoom = "planAnchor-module--imageZoom--de025";
export var imageZoomGatsby = "planAnchor-module--imageZoomGatsby--404f3";
export var italic = "planAnchor-module--italic--d32a6";
export var lightGrey = "planAnchor-module--light-grey--0b5a0";
export var logo = "planAnchor-module--logo--47a19";
export var lora = "planAnchor-module--lora--29c7a";
export var loraBold = "planAnchor-module--lora-Bold--a567d";
export var loraBoldItalic = "planAnchor-module--lora-BoldItalic--bbdd5";
export var loraMedium = "planAnchor-module--lora-Medium--c1f76";
export var loraSemiBold = "planAnchor-module--lora-SemiBold--7cc2e";
export var main = "planAnchor-module--main--08bea";
export var ming = "planAnchor-module--ming--18d91";
export var mingHover2 = "planAnchor-module--ming-hover-2--3a87a";
export var modalOpen = "planAnchor-module--modal-open--fa9b3";
export var noUnderline = "planAnchor-module--no-underline--314f5";
export var openSans = "planAnchor-module--openSans--3aaf5";
export var openSansBold = "planAnchor-module--openSans-Bold--12e95";
export var openSansSemiBold = "planAnchor-module--openSans-SemiBold--b32a3";
export var pageGuide = "planAnchor-module--page-guide--e77a3";
export var partnerLogo = "planAnchor-module--partnerLogo--978fb";
export var planAnchor = "planAnchor-module--planAnchor--88e6b";
export var popUp = "planAnchor-module--popUp--5a62a";
export var popUpAni = "planAnchor-module--pop-up-ani--f00af";
export var quotation = "planAnchor-module--quotation--d1f35";
export var round = "planAnchor-module--round--44ad2";
export var roundCorners = "planAnchor-module--round-corners--aeeb2";
export var softCorners = "planAnchor-module--soft-corners--5b24c";
export var softShadows = "planAnchor-module--soft-shadows--0a95e";
export var softerCorners = "planAnchor-module--softer-corners--e2f2e";
export var softyCorners = "planAnchor-module--softy-corners--bacc2";
export var tableShadow = "planAnchor-module--table-shadow--10308";
export var teal = "planAnchor-module--teal--6a3f4";
export var topNavShadow = "planAnchor-module--top-nav-shadow--9ffd3";
export var topicArea = "planAnchor-module--topicArea--cf986";
export var uppercase = "planAnchor-module--uppercase--cbeaf";
export var wFull = "planAnchor-module--w-full--698ff";
export var white = "planAnchor-module--white--2b75c";
export var womenRed = "planAnchor-module--women-red--a1f29";
export var womenSharp = "planAnchor-module--women-sharp--75cc0";