import React from "react"
import Button from "../../../components/Button"
import Img from "../../../components/Img"
import tick from "../../../images/review/tick.svg"
import cross from "../../../images/review/cross.svg"
import * as style from "./planCard.module.scss"
import { Link } from "gatsby"
import { updateUrlByLocale } from "../../../plugins/common"
import PriceRating from "./priceRating"
import { CustomMarkdown } from "../../../plugins/sanitizehtml"

const PlanCard = ({ data, labels, locale }) => {
  const { name, partner, price, summary, pros, cons } = data
  const {
    cardCtaLabel,
    consLabel,
    learnMoreAboutLabel,
    priceLabel,
    prosLabel,
  } = labels

  return (
    <div
      className={`position-relative bg-mint corners py-lg-4 px-lg-5 px-4 p-3 ${style.planCard}`}
    >
      <span
        className={`position-absolute ${style.anchorPoint}`}
        id={`${partner?.slug}`}
      ></span>
      <div
        className={`d-lg-flex d-block justify-content-between align-items-center`}
      >
        <div className={`flex`}>
          <Link to={updateUrlByLocale("/partners/" + partner?.slug, locale)}>
            <Img image={partner?.logo} className={style.logo} />
          </Link>
          <div className={`ms-2`}>
            <Link
              className={` ming openSans-Bold no-underline`}
              to={updateUrlByLocale("/partners/" + partner?.slug, locale)}
            >
              <h3 className={`mb-0 openSans-Bold`}>{partner?.name}</h3>
            </Link>
            <div className={`black-text`}>{name}</div>
            <div className={`ming openSans-Bold`}>
              {`${priceLabel}:`} <PriceRating price={price} />
            </div>
          </div>
        </div>
        <Button
          lang={locale}
          type={`primary`}
          text={cardCtaLabel}
          url={"/partners/" + partner?.slug}
          className={`${style.cta} my-lg-0 my-2`}
        />
      </div>
      <div className={`mt-2 ${style.summary}`}>
        <CustomMarkdown html={summary} toSanitize={false}></CustomMarkdown>
      </div>

      <div className={`d-lg-flex d-block mt-3`}>
        <div className={`col-lg-6 col-12`}>
          <div className={`flex align-items-center mb-2`}>
            <Img image={tick} className={`${style.icon}`} />
            <div className={`ming openSans-Bold ms-3`}>{prosLabel}</div>
          </div>
          <ul className={`ms-1`}>
            {pros.map((pro, i) => (
              <li key={i}>{pro.title}</li>
            ))}
          </ul>
        </div>
        <div className={`col-lg-6 col-12`}>
          <div className={`flex align-items-center mb-2`}>
            <Img image={cross} className={`${style.icon}`} />
            <div className={`ming openSans-Bold ms-3`}>{consLabel}</div>
          </div>
          <ul className={`ms-1`}>
            {cons.map((con, i) => (
              <li key={i}>{con.title}</li>
            ))}
          </ul>
        </div>
      </div>

      <Button
        lang={locale}
        type={`readMore underline`}
        text={`${learnMoreAboutLabel} ${partner?.name}`}
        url={"/partners/" + partner?.slug}
      />
    </div>
  )
}

export default PlanCard
