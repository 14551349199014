// extracted by mini-css-extract-plugin
export var alertRed = "planCard-module--alert-red--b51ce";
export var anchorPoint = "planCard-module--anchorPoint--5b628";
export var bgBarGrey = "planCard-module--bg-bar-grey--28a96";
export var bgBeige = "planCard-module--bg-beige--84414";
export var bgBeige2 = "planCard-module--bg-beige2--b3a6a";
export var bgCopper = "planCard-module--bg-copper--84f31";
export var bgDaffodil = "planCard-module--bg-daffodil--3ac6a";
export var bgGreyText = "planCard-module--bg-grey-text--2f6d3";
export var bgMing = "planCard-module--bg-ming--7d086";
export var bgMint = "planCard-module--bg-mint--8c8ac";
export var bgRed = "planCard-module--bg-red--23e46";
export var bgRedTrans = "planCard-module--bg-red-trans--27998";
export var bgSand = "planCard-module--bg-sand--b61ca";
export var bgSoftSun = "planCard-module--bg-softSun--b5fa0";
export var bgTeal = "planCard-module--bg-teal--90dc5";
export var bgWhite = "planCard-module--bg-white--373a0";
export var bgWomenSharp = "planCard-module--bg-women-sharp--8dfa5";
export var blackText = "planCard-module--black-text--a67aa";
export var borderMing = "planCard-module--border-ming--c40fd";
export var copper = "planCard-module--copper--7da0e";
export var corners = "planCard-module--corners--cb343";
export var cta = "planCard-module--cta--ca14c";
export var cursorNormal = "planCard-module--cursor-normal--02486";
export var cursorPointer = "planCard-module--cursor-pointer--08f51";
export var customMarkdown = "planCard-module--customMarkdown--e5977";
export var dUnset = "planCard-module--d-unset--fabf5";
export var darkGrey = "planCard-module--dark-grey--97e52";
export var dash = "planCard-module--dash--1af6c";
export var dashBig = "planCard-module--dashBig--40551";
export var deadSalmon = "planCard-module--dead-salmon--fe744";
export var flex = "planCard-module--flex--85048";
export var flex1 = "planCard-module--flex-1--62db9";
export var fontSize12 = "planCard-module--font-size-12--58bd6";
export var fontSize20 = "planCard-module--font-size-20--ccd5d";
export var greyPlaceholder = "planCard-module--grey-placeholder--a080d";
export var greyText = "planCard-module--grey-text--2f4a3";
export var h1sizing = "planCard-module--h1sizing--abc00";
export var h2sizing = "planCard-module--h2sizing--4fe73";
export var hidden = "planCard-module--hidden--e1b41";
export var icon = "planCard-module--icon--1f4a8";
export var imageAni = "planCard-module--imageAni--efef4";
export var imageZoom = "planCard-module--imageZoom--1b555";
export var imageZoomGatsby = "planCard-module--imageZoomGatsby--b01a1";
export var italic = "planCard-module--italic--13257";
export var lightGrey = "planCard-module--light-grey--78b89";
export var logo = "planCard-module--logo--77015";
export var lora = "planCard-module--lora--7f8b0";
export var loraBold = "planCard-module--lora-Bold--b989d";
export var loraBoldItalic = "planCard-module--lora-BoldItalic--565f6";
export var loraMedium = "planCard-module--lora-Medium--769b2";
export var loraSemiBold = "planCard-module--lora-SemiBold--64e03";
export var main = "planCard-module--main--8bd71";
export var ming = "planCard-module--ming--8ad26";
export var mingHover2 = "planCard-module--ming-hover-2--5a93e";
export var modalOpen = "planCard-module--modal-open--a8bef";
export var noUnderline = "planCard-module--no-underline--9e4b2";
export var openSans = "planCard-module--openSans--01e68";
export var openSansBold = "planCard-module--openSans-Bold--30ac4";
export var openSansSemiBold = "planCard-module--openSans-SemiBold--c2999";
export var pageGuide = "planCard-module--page-guide--2361d";
export var planCard = "planCard-module--planCard--048fc";
export var popUp = "planCard-module--popUp--0e5df";
export var popUpAni = "planCard-module--pop-up-ani--d2314";
export var quotation = "planCard-module--quotation--944ab";
export var round = "planCard-module--round--0bc79";
export var roundCorners = "planCard-module--round-corners--cceea";
export var softCorners = "planCard-module--soft-corners--507c6";
export var softShadows = "planCard-module--soft-shadows--dfd2a";
export var softerCorners = "planCard-module--softer-corners--662a6";
export var softyCorners = "planCard-module--softy-corners--890b2";
export var summary = "planCard-module--summary--f11d9";
export var tableShadow = "planCard-module--table-shadow--b21c7";
export var teal = "planCard-module--teal--ef8e0";
export var topNavShadow = "planCard-module--top-nav-shadow--9b793";
export var topicArea = "planCard-module--topicArea--25056";
export var uppercase = "planCard-module--uppercase--1e22e";
export var wFull = "planCard-module--w-full--685b9";
export var white = "planCard-module--white--4f536";
export var womenRed = "planCard-module--women-red--296ef";
export var womenSharp = "planCard-module--women-sharp--322f5";