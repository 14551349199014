// extracted by mini-css-extract-plugin
export var alertRed = "review-module--alert-red--71171";
export var anchorMenu = "review-module--anchorMenu--2e1fd";
export var bgBarGrey = "review-module--bg-bar-grey--eb950";
export var bgBeige = "review-module--bg-beige--eb382";
export var bgBeige2 = "review-module--bg-beige2--8f1b3";
export var bgCopper = "review-module--bg-copper--a9c6b";
export var bgDaffodil = "review-module--bg-daffodil--98b91";
export var bgGreyText = "review-module--bg-grey-text--09c62";
export var bgMing = "review-module--bg-ming--89856";
export var bgMint = "review-module--bg-mint--0c05a";
export var bgRed = "review-module--bg-red--c023c";
export var bgRedTrans = "review-module--bg-red-trans--915e6";
export var bgSand = "review-module--bg-sand--c71c5";
export var bgSoftSun = "review-module--bg-softSun--9849e";
export var bgTeal = "review-module--bg-teal--2fc2b";
export var bgWhite = "review-module--bg-white--4ef2e";
export var bgWomenSharp = "review-module--bg-women-sharp--f997b";
export var blackText = "review-module--black-text--5216b";
export var borderMing = "review-module--border-ming--a51ed";
export var content = "review-module--content--393fe";
export var copper = "review-module--copper--8e4f1";
export var corners = "review-module--corners--599d3";
export var cursorNormal = "review-module--cursor-normal--8dd46";
export var cursorPointer = "review-module--cursor-pointer--61bfb";
export var customMarkdown = "review-module--customMarkdown--c98aa";
export var dUnset = "review-module--d-unset--5e0c2";
export var darkGrey = "review-module--dark-grey--389e9";
export var dash = "review-module--dash--0afed";
export var dashBig = "review-module--dashBig--ba4df";
export var deadSalmon = "review-module--dead-salmon--abed8";
export var desc = "review-module--desc--28cce";
export var flex = "review-module--flex--d71f6";
export var flex1 = "review-module--flex-1--91526";
export var fontSize12 = "review-module--font-size-12--e8a39";
export var fontSize20 = "review-module--font-size-20--7df2c";
export var form = "review-module--form--23d27";
export var greyPlaceholder = "review-module--grey-placeholder--c5de2";
export var greyText = "review-module--grey-text--388bd";
export var h1sizing = "review-module--h1sizing--7cb45";
export var h2sizing = "review-module--h2sizing--43c79";
export var header = "review-module--header--a71fe";
export var hidden = "review-module--hidden--db71a";
export var image = "review-module--image--5ed95";
export var imageAni = "review-module--imageAni--db7f8";
export var imageZoom = "review-module--imageZoom--ae9fd";
export var imageZoomGatsby = "review-module--imageZoomGatsby--d3cac";
export var italic = "review-module--italic--ef2cd";
export var left = "review-module--left--ea0f9";
export var lightGrey = "review-module--light-grey--2498b";
export var logo = "review-module--logo--97ce9";
export var lora = "review-module--lora--a8191";
export var loraBold = "review-module--lora-Bold--2dcd6";
export var loraBoldItalic = "review-module--lora-BoldItalic--3247a";
export var loraMedium = "review-module--lora-Medium--1e396";
export var loraSemiBold = "review-module--lora-SemiBold--91aa6";
export var main = "review-module--main--551db";
export var ming = "review-module--ming--256b6";
export var mingHover2 = "review-module--ming-hover-2--c6401";
export var modalOpen = "review-module--modal-open--d3246";
export var noUnderline = "review-module--no-underline--4f264";
export var openSans = "review-module--openSans--a3408";
export var openSansBold = "review-module--openSans-Bold--a1eee";
export var openSansSemiBold = "review-module--openSans-SemiBold--b786f";
export var pageGuide = "review-module--page-guide--701de";
export var paragraph = "review-module--paragraph--73bcd";
export var popUp = "review-module--popUp--be519";
export var popUpAni = "review-module--pop-up-ani--96971";
export var quotation = "review-module--quotation--dd490";
export var reviewBody = "review-module--reviewBody--18ea7";
export var right = "review-module--right--2a629";
export var round = "review-module--round--b6c04";
export var roundCorners = "review-module--round-corners--f9928";
export var sideBar = "review-module--sideBar--110cd";
export var socialIcon = "review-module--socialIcon--8f2b8";
export var softCorners = "review-module--soft-corners--e3bfa";
export var softShadows = "review-module--soft-shadows--00e99";
export var softerCorners = "review-module--softer-corners--f08e1";
export var softyCorners = "review-module--softy-corners--4c832";
export var tableShadow = "review-module--table-shadow--39d55";
export var teal = "review-module--teal--a666d";
export var topNavShadow = "review-module--top-nav-shadow--31d17";
export var topicArea = "review-module--topicArea--a1a0a";
export var uppercase = "review-module--uppercase--3ec3e";
export var wFull = "review-module--w-full--5f71c";
export var white = "review-module--white--382a4";
export var womenRed = "review-module--women-red--392f4";
export var womenSharp = "review-module--women-sharp--2afae";